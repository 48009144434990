$(function() {

    //console.log( "RiskScreen ready!" );

    // Main Search Form Bindings
    // Switch Based on Type
    $('.target_type').on('change', function(e) {
        var uri = $(this).attr('rel');
        window.location.href = uri;
    });

    // Bind the modal to the submit event
    $('.rsSearchForm').on('submit', function(e) {
        $('#rsPleaseWait').modal('show');
    });

    $('.edd-source').on('change', function(e) {
        checkEddSources();
    });

    $('#onOffadditionalTermssanctions_all').on('change', function(e) {
        $('.sanctions-detail').attr("checked", this.checked);
    });

    checkEddSources();
    $('.sanctions-detail').attr("checked", $('#onOffadditionalTermssanctions_all').is(':checked'));
    
    setupStartDateFilter();
    setupSearchEngineToggles();
});

function checkEddSources() {
    // loop over all of them and see if they're on
    var hasEddSource = false;
    $('.edd-source').each(function( index ) {
        if ( $(this).is(':checked') ) {
            hasEddSource = true;
        }
    });

    if (hasEddSource === false) {
        $('.standard-edd-warning').hide(0);
    } else {
        $('.standard-edd-warning').show(0);
    }
}

function setupStartDateFilter() {
    var date_input = $('input[name="web_date_filter"]'); //our date input has the name "date"
    var container = $('.bootstrap-iso form').length > 0 ? $('.bootstrap-iso form').parent() : "body";
    var options = {
        format: 'dd/mm/yyyy',
        container: container,
        todayHighlight: true,
        autoclose: true,
        maxViewMode: "decade",
    };
    date_input.datepicker(options);
}

function setupSearchEngineToggles() {
    // we need to ensure that at least one of the search engines is switched on. easy with just two!
    $('#search-engine-google').on('change', function(e) {
        var searchEngineBing = $("#search-engine-bing");
        if (!$(this).prop("checked") && !searchEngineBing.prop("checked")) {
            searchEngineBing.prop("checked", true);
        }
    });
    $('#search-engine-bing').on('change', function(e) {
        var searchEngineGoogle = $("#search-engine-google");
        if (!$(this).prop("checked") && !searchEngineGoogle.prop("checked")) {
            searchEngineGoogle.prop("checked", true);
        }
    });
}
