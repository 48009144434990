$(function() {
    setupProfileNameChanges();
    function setupProfileNameChanges() {
        // ensure we only continue if we're on the right page
        var nameFields = $(".profile-names");
        if (nameFields.length == 0) {
            return;
        }

        // add event handler
        nameFields.on("input", function() {
            handleNameChange();
        });

        // setup initial state
        handleNameChange();

        // functions to do the work
        function handleNameChange() {
            var name = $("#profile-first-name").val() + " " + $("#profile-last-name").val();
            $("#profile-name").val(name);
        }
    }
});
