$(function()
{
	// Rating Field
	$('.report-config-control').on('change', function(e){
		handleConfigChange($(this));
	});

	// Set up the show flagged / show all so they can't both be on at the same time
	$("#rsReportShowFlagged").on("change", function(e){
		var showAllCheckBox=$("#rsReportShowAll");
		if ($(this).prop("checked")&&showAllCheckBox.prop("checked"))
		{
			// turned on "show flagged" but "show all" is on so turn "show all" off
			showAllCheckBox.prop("checked", false);
			handleConfigChange(showAllCheckBox);
		}
	});
	$("#rsReportShowAll").on("change", function(e){
		var showFlaggedCheckBox=$("#rsReportShowFlagged");
		if ($(this).prop("checked")&&showFlaggedCheckBox.prop("checked"))
		{
			// turned on "show all" but "show flagged" is on so turn "show flagged" off
			showFlaggedCheckBox.prop("checked", false);
			handleConfigChange(showFlaggedCheckBox);
		}
	});

	$("#showReportCustomise").on('click', function(e){
		e.preventDefault();
		$('#reportCustomiseWrapper').slideDown();
		$('#showReportCustomiseWrapper').hide(0);
		//$('#quickSearchButton').hide(0);
	});

	$('#closeReportCustomise').on('click', function(e){
		e.preventDefault();
		$('#reportCustomiseWrapper').slideUp();
		$('#showReportCustomiseWrapper').show(0);
		//$('#quickSearchButton').show(0);
	});

	initConfigApplication();

	$('#rsReportEmailReport').on('click', function(e){
		e.preventDefault();
		requestEmailReport();
	});

});

function handleConfigChange(changedElement)
{
	console.log('config change: '+changedElement.attr("id"));
	var config_key=changedElement.attr('rel');
	var config_value=changedElement.val();
	var config_is_checkbox=false;
	if (changedElement.hasClass('config_is_checkbox'))
	{
		config_is_checkbox=true;
		if (changedElement.is(':checked'))
		{
			config_value=1;
		}
		else
		{
			config_value=0;
		}
	}
	// Check and toggle all / filtered

	storeConfig(config_key, config_value);
	applyConfigToPreview(config_key, config_value, config_is_checkbox);
}

function storeConfig(config_key,config_value)
{
	var packet={
		config_key: config_key,
		config_value: config_value
	};
	var uri='/report/config/'+searchId;
	$.post(uri, packet).done(function(data){
		return;
	});
}

function applyConfigToPreview(config_key,config_value,config_is_checkbox)
{
	var target=$('div[rel="'+config_key+'"]');
	if (config_is_checkbox===true)
	{
		if (config_value)
		{
			target.show();
		}
		else
		{
			target.hide();
		}
	}
	else
	{
		// Have a value = need to work out if we have a select an input or a dropdown
		if (config_value!='')
		{
			target.html(config_value);
		}
		else
		{
			var content='Not specified';
			try {if (lang=='ja') content='指定されていない';} catch(err) {}

			target.html(content);
		}
	}

}

// Initial load
function initConfigApplication()
{
	$('.report-config-control').each(function(i){
		var config_key=$(this).attr('rel');
		var config_value=$(this).val();
		var config_is_checkbox=false;
		if ($(this).hasClass('config_is_checkbox'))
		{
			config_is_checkbox=true;
			if ($(this).is(':checked'))
			{
				config_value=1;
			}
			else
			{
				config_value=0;
			}
		}
		applyConfigToPreview(config_key, config_value, config_is_checkbox);
	});
}

function requestEmailReport()
{
	$('#rsPleaseWait').modal('show');
	var recipientAddress=$('#rsReportEmailReceipient').val();
	var packet={
		recipientAddress: recipientAddress
	};
	var uri='/report/email/'+searchId;

	$.post(uri, packet).done(function(data){
		$('#rsPleaseWait').modal('hide');
		alert(data.message);
		//$('.rsReportEmailStatusWrapper').html(data.message);
		// setTimeout(function(){
		//	 $('.rsReportEmailWrapper').show(0);
		//	 $('.rsReportEmailStatusWrapper').hide(0);
		// }, 5000);
		return;
	});

	// rsReportEmailWrapper
	// rsReportEmailStatusWrapper
}
