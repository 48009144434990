$(function() {
    
    $('#rsAccuracy').on('input change', function(e) {
        printValue('rsAccuracy','rsAccuracyValue');
    });

    // $("#showSearchPreferences").on('click', function(e) {
    // 	e.preventDefault();
    // 	$('#searchPreferencesWrapper').slideDown();
    // 	$('#showSearchPreferencesWrapper').hide(0);
    //     $('#quickSearchButton').hide(0);
    // });

    // $('#closeSearchPreferences').on('click', function(e) {
    // 	e.preventDefault();
    // 	$('#searchPreferencesWrapper').slideUp();
    // 	$('#showSearchPreferencesWrapper').show(0);
    //     $('#quickSearchButton').show(0);
    // });

});
