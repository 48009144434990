// Avoid `console` errors in browsers that lack a console.
(function() {
	var method;
	var noop = function () {};
	var methods = [
		'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
		'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
		'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
		'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
	];
	var length = methods.length;
	var console = (window.console = window.console || {});

	while (length--) {
		method = methods[length];

		// Only stub undefined methods.
		if (!console[method]) {
			console[method] = noop;
		}
	}
}());



$(function() {

	if (typeof ie8 === 'undefined') {
		$.material.init();	
	}
	
	// Enable Tool Tips
	$('body').tooltip({
		selector: '[rel=tooltip]'
	});

	// Semi Isotopian Functionality for Search Results
	$('.isotope-btn-group').on('change', '.filter-checkbox', function(e) {
		
		if ($(this).is(':checked')) {
			$('.filter-checkbox').attr('checked', false);
			$(this).attr('checked', true);
			filteredClass = $(this).data('filter');
		} else {
			filteredClass = '*';
		}
		filterResults();
		
	});

	// Help Dismiss Call
	
	$('.show-help').on('click', function(e) {
		e.preventDefault();
		$('.help-wrapper').slideDown();
		$('i.show-help').hide(0);
		$('i.close-help').show(0);
	});

	$('.close-help').on('click', function(e) {
		e.preventDefault();
		$('.help-wrapper').slideUp();
		$('i.show-help').show(0);
		$('i.close-help').hide(0);
	});

	
	// Global confirm handler
	$('body').on( "click", ".confirm", function(e) {
		var message = $(this).data('message');
		if (confirm(message)) {
			return true;
		} else {
			e.preventDefault();
		}
	});

	// Tool Tips
	$(function () {
	  $('[data-toggle="tooltip"]').tooltip();
	});

	// New user help message dialog
	$('.rnModal').modal({});
	$('.helpDismiss').on('click', function(e) {
		dismissHelp(1);
	});
	
	// Release notes dialog
	$('.releaseNotesDialog').modal({});
	$('.releaseNotesDismiss').on('click', function(e) {
		var releaseNotesId = $(this).attr('rel');
		dismissReleaseNotes(releaseNotesId);
	});

	// Persistant search fields
	$('.rsSearchForm input').on('change', function(e) {

		// Do we have a checkbox?
		var key = 'rsSearchForm-' + $(this).attr('name');
		var val = $(this).val();
		var inputType = $(this).attr('type');
		if (inputType === 'checkbox') {
			if ($(this).attr('checked') === 'checked') {
				val = true;
			} else {
				val = false;
			}
		}
		
		if (inputType !== 'hidden') {
			// Ok save the thing
			$.jStorage.set(key, val, {TTL: 1800000});
		}
	});

	// Loading function
	var formData = $.jStorage.index();
	var arrayLength = formData.length;
	var target;
	var key;
	var inputName;
	var value;
	var inputType;
	for (var i = 0; i < arrayLength; i++) {
		key = formData[i];
		inputName = key.split("-")[1];
		value = $.jStorage.get(key);
		target = $('input[name="' + inputName + '"]');
		inputType = target.attr('type');
		if (inputType == 'checkbox') {
			if (value === true) {
				target.prop('checked', true);
			} else {
				target.prop('checked', false);
			}
		} else if (inputType != 'radio') {
			// not a checkbox or a radio
			target.val(value);
		}
		if (inputType == 'range') {
			printValue('rsAccuracy','rsAccuracyValue');
		}
	}

	$('.reset-saved').on('click', function(e) 
	{
		var msg='Are you sure you wish to start a new search?\nAll previous results will be cleared';
		try {if (lang=='ja') msg='新しい検索を開始してもよろしいですか？ 以前の結果はすべてクリアされます';} catch(err) {}
		if (confirm(msg))
			$.jStorage.flush();
		else
			e.preventDefault();
	});

	// Credit counter
	$('.credit-wrapper .control').on('click', function(e) {
		if ($(this).hasClass('expand')) {
			$('.credit-wrapper').addClass('full');
		} else {
			$('.credit-wrapper').removeClass('full');
		}
	});

});

// Setup the filters array
var filteredClasses = ['*'];
filteredClass = '*';

function printValue(sliderID, textbox) {
	var x = document.getElementById(textbox);
	var y = document.getElementById(sliderID);
	x.value = y.value + '%';
}

// Dismissal of release notes dialog
function dismissReleaseNotes(releaseNotesId) {
	var uri = '/release-notes/dismiss/' + releaseNotesId;
	$.get( uri, function(data) {
		return;
	});
}

// Dismissal of inital help dialog
function dismissHelp() {
	var uri = '/help/dismiss-initial';
	$.get( uri, function(data) {
		return;
	});
}

function filterResults() {
	var filteredClasses = [];
	// Is this show all?()
	if (filteredClass == '*') {
		// Not much to do here
		filteredClasses = [];
	} else {
		filteredClasses.push(filteredClass);
	}
	
	// Do we have anything to work with?
	
	if (filteredClass == '*') {
		$(".isotope-item").show();
	} else {
		$(".isotope-item").hide();
		$.each( filteredClasses, function( key, filteredClass ) {
			if (filteredClass !== '*') {
				$(".isotope-item" + filteredClass).show();	
			}
		});
	}
}

function clearFilter() {
	console.log('Clearing');
	filteredClasses = ['*'];
	filteredClass = '*';
	$('.filter-checkbox').attr('checked', false);
	filterResults();
}
