$(function() {
    setupWatchlistFilters();

    function setupWatchlistFilters() {
        // ensure we only continue if we're on the watchlist page
        var table = $("table.watchlists");
        if (table.length == 0) {
            return;
        }

        var states = { values: {}, count: 0 };
        var agencies = { values: {}, count: 0 };
        var statuses = { values: {}, count: 0 };
        var descriptions = { values: {}, count: 0 };

        table.find("tr").each(function() {
            var row = $(this);
            var cells = row.find("td");
            if (cells.length != 6) {
                return; // header
            }

            row.attr("data-state", getValueId(states, $(cells[1]).text()));
            row.attr("data-agency", getValueId(agencies, $(cells[2]).text()));
            row.attr("data-status", getValueId(statuses, $(cells[4]).text()));
            row.attr("data-description", getValueId(descriptions, $(cells[5]).text()));
        });

        var stateFilter = $("#watchlistFilterState");
        var agencyFilter = $("#watchlistFilterAgency");
        var statusFilter = $("#watchlistFilterStatus");
        var descriptionFilter = $("#watchlistFilterDescription");

        populateDropdown(stateFilter, states);
        populateDropdown(agencyFilter, agencies);
        populateDropdown(statusFilter, statuses);
        populateDropdown(descriptionFilter, descriptions);

        stateFilter.change(applyFilter);
        agencyFilter.change(applyFilter);
        statusFilter.change(applyFilter);
        descriptionFilter.change(applyFilter);
    }

    function applyFilter() {
        var stateFilter = $("#watchlistFilterState").val();
        var agencyFilter = $("#watchlistFilterAgency").val();
        var statusFilter = $("#watchlistFilterStatus").val();
        var descriptionFilter = $("#watchlistFilterDescription").val();

        // show all then re-hide. quicker than row-by-row filtering strategies
        $("table.watchlists tr").show();
        if (stateFilter != 0) {
            $("table.watchlists tr[data-state!='" + stateFilter + "']").hide();
        }
        if (agencyFilter != 0) {
            $("table.watchlists tr[data-agency!='" + agencyFilter + "']").hide();
        }
        if (statusFilter != 0) {
            $("table.watchlists tr[data-status!='" + statusFilter + "']").hide();
        }
        if (descriptionFilter != 0) {
            $("table.watchlists tr[data-description!='" + descriptionFilter + "']").hide();
        }
    }

    function populateDropdown(element, data) {
        var ids = Object.keys(data.values).sort();
        ids.forEach(function(id) {
            element.append($("<option>", {
                value: data.values[id],
                text: id,
            }));
        });
    }

    function getValueId(data, value) {
        var id = data.values[value];
        if (id == null) {
            id = ++data.count;
            data.values[value] = id;
        }
        return id;
    }
});
