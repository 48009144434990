$(function() {

	// Flag Handling - bind to the flag buttons
	$('.resultsWrapper').on( "click", ".flag", function(e) {
		e.preventDefault();
		var header = $(this).parents('.result');
		var flagType = $(this).data('flag');
		var resultKey = $(this).attr('rel');
		
		// Is it already flagged?
		if (header.hasClass(flagType))
		{
			flagType = 'new';
			header.removeClass('discounted green yellow red');
		} 
		else
		{
			header.removeClass('discounted green yellow red').addClass(flagType);
		}

		flagResult(resultKey, flagType);
	});

	// Comments Handling - bind to the flag buttons
	$('.resultsWrapper').on( "change", ".comments", function(e) {
		e.preventDefault();
		var resultKey = $(this).attr('rel');
		var comment = $(this).val();
		commentResult(resultKey, comment);
	});

	$('.resultsWrapper').on( "click", ".comments-save", function(e) {
		e.preventDefault();
	});

	// Load more results handling
	$('#loadMoreButton').on('click', function(e) {
		$('#rsPleaseWait').modal('show');
	});

	// Tab Controls
	$('.close-tab-button').on('click', function(e) {
		e.preventDefault();
		var tabListId = $(this).attr('rel');
		var tabId = 'a[href="' + $(this).attr('href') + '"]';
		$(tabId).tab('show')

	});

	// Filter Controls
	$("#showFilterPanel").on('click', function(e) {
		e.preventDefault();
		$('#filterPanel').slideDown();
		$('#showFilterPanelWrapper').hide(0);
	});

	$('#closeFilterPanel').on('click', function(e) {
		e.preventDefault();
		$('#filterPanel').slideUp();
		$('#showFilterPanelWrapper').show(0);
	});

	$('.filter-control-show-all').on('click', function(e) {
		e.preventDefault();
		$('.filter-wrap').slideDown();
		$('.filter-notification').hide(0);
	});

	// confirm identity page
	jQuery('.confirm_id .result').on('click',function(aEvent)
	{
		var selectedIndicatorClass='selected';
		if (!jQuery(this).hasClass(selectedIndicatorClass))
		{
			jQuery('.result').removeClass(selectedIndicatorClass);
			jQuery(this).addClass(selectedIndicatorClass);
		}
					
		jQuery('.checkbox_container').css('visibility', 'hidden');											// hide all checkboxes
		jQuery(this).find('.checkbox_container').css('visibility', 'visible');				// show the relevant one

		nameIndex=parseInt(this.id.replace('name_',''));
		name=names[nameIndex];
		nameArray=name.split(',');

		var includeMiddleName=$(this).find('input[type=checkbox]').is(':checked');
		if (!includeMiddleName)
			nameArray[1]='';

		updateSearchName(searchId,nameArray[0],nameArray[1],nameArray[2]);
	});
	
	// Related Entity Loading
	$('body').on('click', '.getRelatedSPW', function(e) {
		e.preventDefault();
		var uri = $(this).attr('href');
		$.get( uri, function( data ) {
			$('#spwRelatedModalContent').html(data);
			$('#spwRelatedModal').modal();
		});
	});
});

var riskscreenDebug = false;

/*******************************************************************************
	LOAD MORE HELPERS
*/

function handlePagination(uri)
{
	if( uri ) {
		$('#loadMoreButton').attr('href', uri);
		$('#loadMoreWrapper').show(0);
	} else {
		$('#loadMoreButton').attr('href', '#');
		$('#loadMoreWrapper').hide(0);
	}
	$('#rsPleaseWait').modal('hide');
}

/*******************************************************************************
	FLAGGING
*/

function flagResult(resultKey, flagType) {
	var uri = '/results/' + searchId + '/' + resultKey + '/' + flagType;
	$.get( uri, function( data ) {
		return;
	});
}


/*******************************************************************************
	RESULT COMMENTS
*/

function commentResult(resultKey, comment) {
	var uri = '/results/' + searchId + '/' + resultKey + '/comments';
	$.post( uri, {comment: comment}, function( data ) {
		return;
	});
}

/*******************************************************************************
	UPDATE THE SEARCH TERM
*/

function updateSearchName(aSearchId,aForeName,aMiddleNames,aLastName)
{
	// if you use a blank string in the path, the routing gets confused! :-/
	var forename=aForeName.length>0 ? encodeURI(aForeName) : 0;
	var middlename=aMiddleNames.length>0 ? encodeURI(aMiddleNames) : 0;
	var lastname=aLastName.length>0 ? encodeURI(aLastName) : 0;
	
	var uri = '/results/update_search_name/'+aSearchId+'/'+forename+'/'+middlename+'/'+lastname;
	jQuery.get(uri,function(data){
		return;
	});
}

